export interface IResource {
    id: number;
}

export interface INormalizedResourceList<T extends IResource> {
    [id: number]: T
}

/* General
============================================*/

export interface ISupportRequest {
    userId: number;
    subject: string;
    body: string;
}

export enum FeedbackType {
    MedexInsights = 0
}
export interface IFeedbackRequest {
    id: number;
    userId: number;
    type: FeedbackType;
    feedback: string;
    projectFileId: number;
    isHelpful?: Boolean;
}

export interface IAppInfo {
    medexVersion: string;
    referenceLibraryFileCount: number;
    referenceLibraryLastUpdated: string;
}

export interface IClientDownload {
    version: string;
    updated: string;
    link: string;
}

/* User
============================================*/

export interface IUser extends IResource {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    userGroups: IUserGroupUser[];
    permissions: IUserGroupPermission[];
    isAdmin: boolean;
    newPassword?: string;
    verified: boolean;
    preferences?: IUserPreferences;
}

export interface IUserPreferences {
    userId: number;
    projectNotifications: boolean;
    fileNotifications: boolean;
    referenceLibraryNotifications: boolean;
}

export interface IUserInviteResponse {
    userId: number;
    token: string;
    password?: string;
}

export interface IAutocompleteResult {
    id: number;
    displayText: string;
}

/* User Groups
============================================*/

export interface IUserGroup extends IResource {
    name: string;
    description: string;
    permissions: IGroupPermissionDetails[];
    users: IUserGroupUser[];
    isDefault: boolean;
}

export interface IUserGroupUser {
    userId: number;
    userGroupId: number;
}

export interface IGroupPermissionDetails {
    description: string;
    permission: Permission;
}

export interface IUserGroupPermission {
    userId: number;
    userGroupId: number;
    permission: Permission;
}

export enum Permission {
    Public = 0,
    ReadGroupUsers = 10,
    EditGroupUsers = 11,
    DeleteGroupUsers = 12,
    EditGroupSettings = 20,
    ReadProjects = 30,
    EditProjects = 31,
    DeleteProjects = 32,
    ReadFiles = 40,
    EditFiles = 41,
    DeleteFiles = 42,
    DownloadReports = 51,
    RequestCaseAnalysis = 60
}

/* Roles
============================================*/

export interface IRole {
    id: number;
    name: string;
    description: string;
}

/* Projects
============================================*/

export interface IProject {
    id: number;
    userId: number;
    userGroupId: number;
    caseId: string;
    examinerName: string;
    labId: string;
    created?: string;
    updated?: string;
    submitted?: string;
    status: ProjectStatus;
    files: IProjectFile[];
}

export enum ProjectStatus {
    Draft = 1,
    InProgress = 2,
    Complete = 3,
    Error = 99
}

export interface IProjectSummary {
    project: IProject;
    files: IProjectFile[];
    medexVersion: string;
    referenceLibraryVersion: string;
    filesSubmitted: number;
    filesSupported: number;
    filesWithKnownSignature: number;
}

/* Project Files
============================================*/

export interface IProjectFile {
	id: number;
	projectId: number;
	originalFilename: string;
	path?: string;
	filename?: string;
	aquisitionType: FileAquisitionMethod;
	status: FileStatus;
	flagStatus: FileFlagStatus;
	created?: string;
	updated?: string;
	sha256Hash: string;
	signature1RefMatch: boolean;
	signature2RefMatch: boolean;
	originatingDevice?: string;
	lastGeneration?: string;
	modificationStatus?: FileModificationStatus;
	referenceVersion?: string;
    uploadId: string;
    rating?: IProjectFileRating;
    file?: File;
	embeddedModelDiscrepancy: boolean;
}

export interface IProjectFileResultSummary {
	projectFileId: number;
	projectId: number;
	filename: number;
	signatureMatchType?: SignatureMatchType;
	confidence: string;
	brand?: string;
	model?: string;
	lastGeneration?: string;
	modificationStatus?: FileModificationStatus;
	status: FileStatus;
	signature1Description?: string;
	signature2Description?: string;
}
export interface IProjectFileChunk {
    data: Blob | null;
    fileId: number;
    filename: string;
    originalFilename: string;
    part: number;
    parts: number;
    projectId: number;
    status: FileChunkStatus;
    uploadId: string;
}

export enum SignatureMatchType {
    NoMatch = 0,
    FullMatch = 1,
    PartialMatch = 2
}
export enum MedexInsightType {
    BRAND_INDICATOR = 1,
    DEVICE_INDICATOR = 2,
    LAST_GENERATION_INDICATOR = 3,
    NOT_ORIGINAL_DEVICE_INDICATOR = 4,
    AUTOMATED_INSIGHT = 5,
    APPLE_CREATE_DATE_INDICATOR = 6,
    GENERAL_DATE_INDICATOR = 7
}
export interface IMedexInsight {
    insightType: MedexInsightType;
    description: string;
}
export enum FileAquisitionMethod {
    NotSet = 0,
    DirectUpload,
    DirectUrl,
    YouTubeDL,
    Facebook,
    MetadataUpload
}

export enum FileStatus {
    Uploading = 1,
    UploadComplete,
    Downloading,
    DownloadComplete,
    Processing,
    ProcessingComplete,
    Draft,
    ProcessingCompleteUnsupported,
    UploadError,
    Error = 99
}

export enum FileFlagStatus {
    NotFlagged = 1,
    Flagged = 2,
    Matched = 3
}

export interface IFileGenerationHistory {
    generationHistory: IFileGenerationList[];
    lastGenerations: string[];
    lastGenerationCategory: string;
    originalDevice: boolean;
}

export interface IFileGenerationList {
    fileCount: number;
    deviceCount: number;
    generations: IFileGeneration[];
}

export interface IFileGeneration {
    count: number;
    description: string;
    index: number;
    type: FileGenerationType;
    processes?: string[];
}
export interface IFileDeviceGeneration {
    modelName: string;
    brandName: string;
    generations?: IFileGeneration[];
}
export interface IFileOriginatingDevice {
    classifiedBrand: string;
    classifiedDevice: string;
    matchType: SignatureMatchType;
    signatureBrands: string[];
    signatureDevices: string[];
}

export enum SignatureMatchType {
    FULL_MATCH,
    PARTIAL
}

export enum FileModificationStatus {
    NotModified = 1,
    PossiblyModified,
    Modified
}

export enum FileGenerationType {
    OriginalDevice = 1,
    Generation
}

export interface IFileExaminationResult {
    mediaType: MediaType;
    file: IProjectFile;
	summary: IFileSummary;
	generationHistory: IFileGenerationHistory,
    originatingDevice: IFileOriginatingDevice,
	metadata: Record<string, string>;
	testResults: IFileTestResults;
	proprietaryStructures: IFileStructure[];
    unknownStructures: IFileStructure[];
    deviceGenerationHistory: IFileDeviceGeneration[];
	fileNotes: string;
    medexInsights: IMedexInsight[];
    c2PA: IProjectFileC2PA;
    lastGenerationClassification: IFileClassification;
}

export interface IAddUserResource {
    canAdd: boolean;
    quantity: number;    
}
export interface IFileClassification {
    predictedValue: string;
    predictedProbability: number;    
}
export interface IFileExaminationReportOptions {
    includeProjectInformation: boolean;
    includeFileSummary: boolean;
    includeSimilarityAnalysisResult: boolean;
    includeStructureAnalysis: boolean;
    includeModificationTests: boolean;
    includeValidationTests: boolean;
    includeFileComments: boolean;
    includeFileMetadata: boolean;
    includeStructuralData: boolean;
    includeProprietaryData: boolean;
    includeUnknownData: boolean;
    includeMedexInsights: boolean;
    includeC2PA: boolean;
}

export interface IFileSummary {
	filename: string;
	format: string;
	projectId: string;
	processed: string;
	checksum: string;
	medexVersion: string;
	referenceLibraryVersion: string;
	fileSignature: string;
}

export interface IProjectFileC2PA {
	id: number;
	projectFileId: number;
	hasManifest: boolean;
	hasValidManifest: boolean;
}

export interface IFileTestResults {
	validationResults: IFileTestResult[];
	modificationResults: IFileTestResult[];
}

export interface IFileTestResult {
	passed: boolean;
	description: string;
	errorDescription: string;
	start: number;
	end: number;
}

export interface IFileStructure {
    id: number;
    projectFileId: number;
	name: string;
	size: number;
	start: number;
	end: number;
    hasRemotePath: boolean;
}

export interface IFileStructureData {
    binary: string;
    hex: string;
    text: string;
    url: string;
}

export interface IFileSignature {
    structures: IFileSignatureStructure[];
}

export interface IFileSignatureStructure {
    id: number;
    name: string;
    depth: number;
    percentage: number;
    children: IFileSignatureStructure[];
    sequenceHashId?: number;
    sequenceHashDescription?: string;
    sequenceHashLabel?: String;
    sequenceHashLabelType: SequenceLabelType;
}
export interface IInsightTest
{
    id: string;
    label: string;
    name: string;
    value: boolean | undefined;
}
export interface IPathEvaluationResult
{
    tests: IInsightTest[];
    match: boolean;
    resultText: string;
    pathId: number;
}
export enum SequenceLabelType {
    Device = 1,
    Brand,
    LastGeneration
}

export enum MediaType {
    Video = 0,
    Image
}
export interface IProjectFileComment {
    id: number;
    projectFileId: number;
    userId: number;
    body: string;
    created: string;
    edited?: string;
    createdBy?: string;
    editedBy?: string;
}

export interface IProjectFileRating {
    id: number;
    projectFileId: number;
    userId: number;
    rating: number;
    description: string;
    created: string;
    updated?: string;
}

export interface IPublicFileLog {
    id: number;
    projectFileId: number;
    message: string;
    created: string;
    visible: boolean;
}

/* Uploads
============================================*/

export enum FileChunkStatus {
    Queued = 1,
    Uploading = 2,
    Complete = 3,
    Retrying = 4,
    Reading = 5,
    Error = 99
}

export enum FileUploadStatus {
	NotStarted=1,
	Uploading=2,
	Complete=3,
	Retrying=4,
	Offline=5,
	Paused=6,
    Completing=7,
    Canceled=8,
	Error=99,
	FileUploadStatus
}

export type FileUploadProgress = {
    filename: string;
    percent: number;
    status: FileUploadStatus,
    currentChunk: number;
    totalChunks: number;
}

export enum FileUploadEvent {
    Complete = 'complete',
    Progress = 'progress',
    Retry = 'retry',
    Error = 'error',
    Online = 'online',
    Offline = 'offline'
};



/* Subscribers
============================================*/

export interface ISubscriber {
    id: number;
    name: string;
    contactEmail?: string;
    subscriptionType: SubscriptionType;
    logoImageUrl?: string;
    allowClientDownload?:boolean;
}

export enum SubscriptionType {
    Basic = 1,
    Advanced
}

/* Device Lists
============================================*/

export interface IDeviceList {
    deviceBrands: IDeviceBrand[];
    lastGenerations: string[];
    deviceGenerations: IDeviceBrandGeneration[];
}

export interface IDeviceBrand {
    brandName: string;
    models: string[];
}

export interface IDeviceBrandGeneration {
    brandName: string;
    modelGenerations: IModelGeneration[];
}

export interface IModelGeneration {
    modelName: string;
    generations: string[];
}