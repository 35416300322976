
import FileDeviceGeneration from './FileDeviceGeneration.vue';
import FileResultCard from './FileResultCard.vue';
import MxInfoButton from '@/components/global/MxInfoButton.vue';
import MxModal from '@/components/global/MxModal.vue';
import FileSignatureStructure from './FileSignatureStructure.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileDeviceGeneration, IFileExaminationResult, IFileGenerationHistory, IFileOriginatingDevice, SignatureMatchType, IFileSignature,IFileSignatureStructure, FileFlagStatus, SequenceLabelType } from '@/types/resource.types';

@Component({
    name: 'FileOriginatingDevice',
    components: {
        FileResultCard,
        FileDeviceGeneration,
        FileSignatureStructure,
        MxModal,
        MxInfoButton
    }
})
export default class FileOriginatingDevice extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Object, required: true})
    readonly fileResult: IFileExaminationResult;
    
    @Prop({type: Array, required: true})
    readonly devices: IFileDeviceGeneration[];

    @Prop({type: Object, required: false})
    readonly signature: IFileSignature | null;

    showModal: boolean = false;
    showStructModal: boolean = false;

    /* Methods
    ============================================*/
    async loadData() {
        if(!this.$hasAdvancedSubscription) return;
        this.$loading = true;
        try {
            this.showModal = true;
        } catch(e) {
            this.showModal = false;
        }
        this.$loading = false;
    }
    loadSignature(){
            this.showStructModal = !this.showStructModal;
    }

    /* Computed
    ============================================*/

    get lastGenerationCategory(): string {
        if(this.history.lastGenerationCategory){
            return this.history.lastGenerationCategory;
        }
        return "Uncategorized"
    }
    get structures(): IFileSignatureStructure[] {
        return this.signature?.structures ?? [];
    }
    get device(): IFileOriginatingDevice {
        return this.fileResult?.originatingDevice;
    }
    get showMatches(): Boolean {
        return this.device && this.device?.matchType === SignatureMatchType.FULL_MATCH;
    }
    get classifiedBrand(): String {
        return this.fileResult.originatingDevice ? this.fileResult.originatingDevice.classifiedBrand : '';
    }
    get classifiedDevice(): String {
        return this.fileResult.originatingDevice ? this.fileResult.originatingDevice.classifiedDevice : '';
    }
    get classifiedLastGeneration(): String {
        return this.fileResult.lastGenerationClassification?.predictedValue ? this.fileResult.lastGenerationClassification.predictedValue : '';
    }

    get embeddedModelDiscrepancy(): boolean {
        return this.fileResult?.file?.embeddedModelDiscrepancy ?? false;
    }
    get history(): IFileGenerationHistory {
        return this.fileResult?.generationHistory;
    }
    
    get matchIcon(): string {
        if(this.device?.matchType === SignatureMatchType.FULL_MATCH) {
            return 'mdi-check-circle';
        }
        return '';
    }

    get matchText(): string {
        if(this.device?.matchType === SignatureMatchType.FULL_MATCH) {
            return 'Full Signature Match';
        }
        return 'No Signature Match';
    }

    get matchType(): string {
        if(this.device?.matchType === SignatureMatchType.FULL_MATCH) {
            return 'success';
        }
        return '';
    }

    get fileIsFlagged(): boolean {
        return this.fileResult?.file?.flagStatus == FileFlagStatus.Flagged
    }

    getPercentage(percent: number): string {
        if(!percent || percent < 0) return '0%';
        return (percent * 100).toFixed(2) + '%';
    }
}

