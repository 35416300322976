
import authService from '@/services/AuthService';
import infoService from '@/services/InfoService';
import permissionService from '@/services/PermissionService';
import userGroupService from '@/services/UserGroupService';
import { ActionTree, GetterTree, StoreOptions } from 'vuex';
import { IRootState } from '@/types/store.types';
import { make } from 'vuex-pathify';
import { SubscriptionType } from '@/types/resource.types';

/*============================================================
 == State
/============================================================*/

const state: IRootState = {
    appInfo: null,
    permissionsList: [],
    serverError: null,
    user: null,
    userGroups: [],
    showGlobalLoader: false,
    subscriber: null
};

/*============================================================
 == Mutations
/============================================================*/

const mutations = {
    ...make.mutations(state)
};

/*============================================================
 == Actions
/============================================================*/

const actions: ActionTree<IRootState, any> = {

    async getAppInfo({commit}) {
        let info = await infoService.getAppInfo();
        commit('SET_APP_INFO', info);
    },

    async getPermissions({commit}) {
        let list = await permissionService.getAll();
        commit('SET_PERMISSIONS_LIST', list);
    },

    async getUserGroups({commit}) {
        let groups = await userGroupService.getAll();
        commit('SET_USER_GROUPS', groups);
    },

    async getSubscriber({commit}) {
        let subscriber = await authService.getSubscriber();
        commit('SET_SUBSCRIBER', subscriber);
    },

    async loadRootData({dispatch}) {
        return await Promise.all([
            dispatch('getAppInfo'),
            dispatch('getPermissions'),
            dispatch('getUserGroups'),
            dispatch('getSubscriber')
        ]);
    }

};

/*============================================================
 == Getters
/============================================================*/

const getters: GetterTree<IRootState, any> = {
    isLoggedIn(state): boolean {
        return state.user !== null;
    },
    hasAdvancedSubscription(state): boolean {
        return state.subscriber?.subscriptionType == SubscriptionType.Advanced;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
} as StoreOptions<IRootState>;
