
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IFileDeviceGeneration } from '@/types/resource.types';

@Component({
    name: 'FileDeviceGeneration',
})
export default class FileDeviceGeneration extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Array, required: true, default: () => []})
    readonly devices: IFileDeviceGeneration[];

    @Prop({type: String, required: false})
    readonly classifiedBrand:String;

    @Prop({type: String, required: false})
    readonly classifiedModel:String;

    @Prop({type: String, required: false})
    readonly classifiedLastGeneration:String;


    showModal: boolean = false;
    /* Computed
    ============================================*/
    get generationCount(): number[] {
        if(!this.devices) return [];
        var max = 0;
        for(var d = 0; d < this.devices.length; d++){
            var thisDevice = this.devices[d];
            if(!thisDevice.generations) continue;
            if(thisDevice.generations.length > max){
                max = thisDevice.generations.length;
            }
        }
        var toReturn = [];
        for(var d = 1; d <= max; d++){
            toReturn.push(d);
        }
        return toReturn;
    }
    modelMatch(brand:String, model:String):Object {
        if (!this.classifiedModel) return {};
        var match = this.classifiedModel == brand + ' ' + model;
        return {
            'model-match': match,
        };
    }
    brandMatch(brand:String, model:String):Object {
        if (!this.classifiedBrand) return {};
        var match = this.classifiedModel == brand + ' ' + model;
        return {
            'model-match': match,
            'brand-match': this.classifiedBrand == brand && !match
        };
    }
    lastGenMatch(lastGeneration:String):Object {
        if (!this.classifiedLastGeneration) return {};
        var match = this.classifiedLastGeneration == lastGeneration;
        return {
            'model-match': match,
        };
    }

    /* Props
    ============================================*/
    getPercentage(percent: number): string {
        if(!percent || percent < 0) return '0%';
        return (percent * 100).toFixed(2) + '%';
    }

}

