
import FileExaminationReportOptions from '@/models/FileExaminationReportOptions';
import projectService from '@/services/ProjectService';
import MxModal from '@/components/global/MxModal.vue';
import Vue from 'vue';
import { AnyObject } from '@/types/generic.types';
import { appConfig } from '@/config/app.config';
import { appendQueryParams } from '@/utils/string.utils';
import { Component, Prop } from 'vue-property-decorator';
import { Permission } from '@/types/resource.types';
import { getPropertyName } from '@/utils/object.utils';

@Component({
    name: 'ProjectOptions',
    components: {
        MxModal
    }
})
export default class ProjectOptions extends Vue {

    /* Props
    ============================================*/

    @Prop({type: Number, required: true})
    readonly projectId: number;

    /* Data
    ============================================*/
    reportOptionCheckboxes = reportOptionCheckboxes;
    showGenerateReportModal: boolean = false;
    selectedOptions: string[] = reportOptionCheckboxes.map(x => x.key);

    /* Computed
    ============================================*/
    get canDownloadReports(): boolean {
        return this.$hasPermission(Permission.DownloadReports);
    }

    /* Methods
    ============================================*/

    async cancelProject() {
        try {
            if(!(await this.confirmCancel())) return;
            this.$loading = true;
            await projectService.delete(this.projectId);
            this.$router.push(this.$paths.PROJECTS);
        } catch(e) {
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }
    async confirmCancel(): Promise<boolean | undefined> {
        return await this.$confirm(`
            Are you sure you want to delete this project? Any associated files will also be deleted.
            This action cannot be undone.`
        ,{
            icon: 'mdi-alert',
            title: 'Delete Project?'
        });
    }
    buildOptions() {
        let options = new FileExaminationReportOptions() as AnyObject;
        Object.keys(options).forEach(key => {
            options[key] = !!this.selectedOptions.find(x => x === key);
        });
        return options as FileExaminationReportOptions;
    }

    async openReportModal() {
        this.selectedOptions = reportOptionCheckboxes.map(x => x.key);
        this.showGenerateReportModal = true;
    }

    async generateReport() {
        try{
            this.$loading = true;
            let url = appConfig.BACKEND_URL + `/project/${this.projectId}/report`;
            let options = this.buildOptions();
            url = appendQueryParams(url, options);
            let fn = 'magnet-verify-' + this.projectId + '-report.pdf';
            this.saveContent(url, fn);
            this.showGenerateReportModal = false;
            this.$loading = false;
        } 
        catch(e) {
            this.$logger.logError(e);
        } finally {
            this.$loading = false;
        }
    }
    saveContent(fileContents:string, fileName:string){
        const link = document.createElement('a');
        link.download = fileName;
        link.href = fileContents;
        link.click();
    }
}

const options = new FileExaminationReportOptions();

const reportOptionCheckboxes = [{
    label: 'Include Verify Insights',
    key: getPropertyName(options, x => x.includeMedexInsights)
},{
    label: 'Include Project Information',
    key: getPropertyName(options, x => x.includeProjectInformation)
},{
    label: 'Include File Summary',
    key: getPropertyName(options, x => x.includeFileSummary)
},{
    label: 'Include Structural Analysis',
    key: getPropertyName(options, x => x.includeStructureAnalysis)
},{
    label: 'Include Attribute Similarity Analysis',
    key: getPropertyName(options, x => x.includeSimilarityAnalysisResult)
},{
    label: 'Include Modification Tests',
    key: getPropertyName(options, x => x.includeModificationTests)
},{
    label: 'Include Validation Tests',
    key: getPropertyName(options, x => x.includeValidationTests)
},{
    label: 'Include File Comments',
    key: getPropertyName(options, x => x.includeFileComments)
},{
    label: 'Include Decoded Metadata',
    key: getPropertyName(options, x => x.includeFileMetadata)
},{
    label: 'Include File Signature',
    key: getPropertyName(options, x => x.includeStructuralData)
},{
    label: 'Include Proprietary Data',
    key: getPropertyName(options, x => x.includeProprietaryData)
},{
    label: 'Include Unknown Data',
    key: getPropertyName(options, x => x.includeUnknownData)
},{
    label: 'Include C2PA Validation',
    key: getPropertyName(options, x => x.includeC2PA)
}];

